/*make sure to import all the other css files here for them to work in the project*/

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  @font-face {
    font-family: "Manrope-Bold";
    src: url("../fonts/Manrope/Manrope-Bold.ttf");
  }
  @font-face {
    font-family: "Manrope-ExtraBold";
    src: url("../fonts/Manrope/Manrope-ExtraBold.ttf");
  }
  @font-face {
    font-family: "Manrope-ExtraLight";
    src: url("../fonts/Manrope/Manrope-ExtraLight.ttf");
  }
  @font-face {
    font-family: "Manrope-Light";
    src: url("../fonts/Manrope/Manrope-Light.ttf");
  }
  @font-face {
    font-family: "Manrope-Medium";
    src: url("../fonts/Manrope/Manrope-Medium.ttf");
  }
  @font-face {
    font-family: "Manrope-Regular";
    src: url("../fonts/Manrope/Manrope-Regular.ttf");
  }
  @font-face {
    font-family: "/Manrope-SemiBold";
    src: url("../fonts/Manrope/Manrope-SemiBold.ttf");
  }
  @font-face {
    font-family: "Inter-Regular";
    src: url("../fonts/Inter/Inter-Regular.ttf");
  }
}

progress::-webkit-progress-bar {
  background-color: #28272710;
}
progress::-webkit-progress-value {
  background-color: #bdd6ab;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #d4d4d4;
  border-radius: 20px;
}

::-webkit-scrollbar-button {
  opacity: 0;
}

.blur-background {
  backdrop-filter: blur(7.5px);
}

.react-calendar__navigation button {
  color: #191714;
  min-width: 60px;
  margin-top: 8px;
  font-family: Manrope-Bold, Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.min-width-10px {
  min-width: 10px;
}

.max-width-400px {
  max-width: 400px;
}

.position-right-5vw {
  right: 5vw;
}

.max-width-243 {
  max-width: 243px;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
}
.react-calendar__navigation__prev-button {
  font-size: 30px !important;
  font-weight: 500 !important;
  font-family: "Manrope-Regular", sans-serif !important;
}
.react-calendar__navigation__next-button {
  font-size: 30px !important;
  font-weight: 500 !important;
  font-family: "Manrope-Regular", sans-serif !important;
}

.react-calendar__month-view {
  height: 100% !important;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__month-view__days__day {
  height: 55px;
}

.react-calendar__tile--active {
  background: #c3e9fc;
  color: #191714;
  font-family: "Manrope-Bold", sans-serif;
  border-radius: 50%;
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0;
}

.radio-label {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.radio-custom {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid grey;
  border-radius: 50%;
  background-color: white;
}

.radio-label input[type="radio"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.radio-label input[type="radio"]:checked + .radio-custom {
  position: absolute;
  top: 50%;
  left: 0;
  border-width: 4px;
  border-color: #f6b58f;
}

input[type="time"] {
  position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  cursor: pointer;
  background: transparent;
}

.MuiPaper-elevation8 {
  margin-top: 10px;
  border-radius: 20px !important;
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1) !important;
}
.css-1dmzujt {
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1) !important;
}

.reactEasyCrop_Container {
  border-radius: 20px;
}
.Toastify__toast-icon {
  width: 50px !important;
}

.Toastify__toast-body {
  font-size: 16px !important;
  font-family: "Manrope", sans-serif !important;
  color: #191714 !important;
  font-weight: 400 !important;
  padding: 0px !important;
}

.recharts-cartesian-grid-horizontal line:last-of-type {
  display: none;
}

.Mui-selected {
  background-color: #c3e9fc !important;
  color: #191714 !important;
  font-size: "16px";
  line-height: "21px";
  font-weight: 400;
}
.MuiPickersCalendarHeader-label {
  color: #191714 !important;
  font-size: "18px";
  line-height: "24px";
  font-weight: 700;
  font-family: sans-serif;
}

.react-pdf__Page__canvas {
  width: 80% !important;
  height: 90% !important;
}
.react-pdf__Document {
  padding: 10px !important;
}

.pg-viewer-wrapper {
  width: 100% !important;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 31px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #585858;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #f6b58f;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.h-max-content {
  height: max-content;
}


.truncate-multiline{
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden; 
}

.swiper-pagination {
  bottom: 0;
}

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background: transparent;
  border: 1px solid #F3F3F3;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  width: 20px;
  height: 6px;
  transition: width 0.5s;
  border-radius: 5px;
  background: #F3F3F3;
  border: 1px solid transparent;
}
